<template>
  <div class="wx-apply">
    <div class="item">
      <div class="item-title">
        <p>企业基本信息</p>
      </div>
      <el-form ref="businessForm" :model="info" :rules="rules" label-width="120px" class="item-form" size="small">
        <el-form-item label="企业名称" prop="name">
          <el-input v-model="info.name" placeholder="请输入企业名称"></el-input>
        </el-form-item>

        <el-form-item label="主营业务" prop="qualificationType">
          <el-select v-model="info.qualificationType">
            <el-option v-for="qualificationType in qualificationTypes" :value="qualificationType">
              {{qualificationType}}
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="注册号/统一社会信用代码" prop="licenseNumber">
          <el-input v-model="info.licenseNumber" placeholder="请输入统一信用代码"></el-input>
        </el-form-item>
        <el-form-item label="营业执照照片" prop="licenseCopyUrl">
          <el-row>
            <el-col :span="14">
              <upload :url.sync="info.licenseCopyUrl" :fileShowType="1" :uuid="employee.uuid" class="upload"></upload>
            </el-col>
            <el-col :span="10" class="example">
              <p>示例图</p>
              <img src="http://b.yunka-it.cn/material/566b84d60eedef19e3045cfaa380fd43.png">
            </el-col>
            <el-col class="explain">必须为彩色图片且小于500k，文件格式为jpg</el-col>
          </el-row>
        </el-form-item>

        <el-form-item label="食品许可证">
          <el-row>
            <el-col :span="14">
              <upload :url.sync="info.qualifications" :fileShowType="1" :uuid="employee.uuid" class="upload"></upload>
            </el-col>
            <el-col :span="10" class="example">
              <p>示例图</p>
              <img src="http://b.yunka-it.cn/material/1798fe4416587fbbb4057aa35bca70a3.png">
            </el-col>
            <el-col class="explain">必须为彩色图片且小于500k，文件格式为jpg</el-col>
          </el-row>
        </el-form-item>
      </el-form>
    </div>

    <div class="item">
      <div class="item-title">
        <p>银行开户行信息</p>
      </div>
      <el-form ref="form" :model="info" :rules="bankAccountInfoRules" label-width="120px"
               class="item-form" size="small">
        <el-form-item label="开户名称" prop="name">
          <el-input v-model="info.accountName" placeholder="请输入开户行名称"></el-input>
        </el-form-item>

        <el-form-item label="开户银行" prop="bank">
          <el-select v-model="info.accountBank">
            <el-option v-for="bank in banks" :value="bank">{{bank}}</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开户银行省市" prop="bankCode">
          <el-cascader
            style="width: 100%;"
            size="mini"
            @change="handleTestchange"
            :options="options"
            :props="{ expandTrigger: 'hover' }">
          </el-cascader>
        </el-form-item>
        <el-form-item label="开户支行全称" prop="bankName">
          <el-input v-model="info.bankName" placeholder="请输入开户支行全称，如中国工商银行股份有限公司北京市分行营业部"></el-input>
        </el-form-item>

        <el-form-item label="银行账号" prop="accountNumber">
          <el-input v-model="info.accountNumber" placeholder="请输入银行账号"></el-input>
        </el-form-item>
      </el-form>
    </div>

    <div class="item">
      <div class="item-title">
        <p>法人信息</p>
      </div>
      <el-form ref="identityForm" :model="info" :rules="identityRules" label-width="120px" class="item-form"
               size="small">
        <el-form-item label="身份证姓名" prop="idCardName">
          <el-input v-model="info.idCardName" placeholder="请输入身份证姓名"></el-input>
        </el-form-item>
        <el-form-item label="身份证号码" prop="idCardNumber">
          <el-input v-model="info.idCardNumber" placeholder="请输入身份证18位号码"></el-input>
        </el-form-item>

        <el-form-item label="身份证有效期" prop="cardPeriodBegin">
          <el-date-picker
            v-model="info.cardPeriodBegin"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            placeholder="选择日期">
          </el-date-picker>
          -
          <el-date-picker
            v-model="info.cardPeriodEndValue"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            :disabled="checked"
            placeholder="选择日期">
          </el-date-picker>
          &nbsp;&nbsp;&nbsp;
          <el-checkbox v-model="checked">永久</el-checkbox>
        </el-form-item>
        <el-form-item label="身份证人像面照片" prop="idCardCopyUrl">
          <el-row>
            <el-col :span="14">
              <upload :url.sync="info.idCardCopyUrl" :uuid="employee.uuid" :fileShowType="1" class="upload"></upload>
            </el-col>
            <el-col :span="10" class="example">
              <p>示例图</p>
              <img src="http://b.yunka-it.cn/material/7150379ebbfa0485aea1c7d0b79fd778.png">
            </el-col>
            <el-col class="explain">必须为彩色图片且小于2M，文件格式为jpg</el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="身份证国徽面照片" prop="idCardNationalUrl">
          <el-row>
            <el-col :span="14">
              <upload :url.sync="info.idCardNationalUrl" :uuid="employee.uuid" :fileShowType="1"
                      class="upload"></upload>
            </el-col>
            <el-col :span="10" class="example">
              <p>示例图</p>
              <img src="http://b.yunka-it.cn/material/8661264b85a651b05e5d839056db463c.png">
            </el-col>
            <el-col class="explain">必须为彩色图片且小于2M，文件格式为jpg</el-col>
          </el-row>
        </el-form-item>

      </el-form>
    </div>

    <div class="item">
      <div class="item-title">
        <p>联系人信息(十分重要)</p>
      </div>
      <el-form :model="info" ref="contactForm" :rules="contactInfoRule" label-width="120px"
               class="item-form" size="small">
        <el-form-item label="商户简称" prop="merchantShortName">
          <el-input v-model="info.merchantShortName" placeholder="请输入商户简称，用于支付展示"></el-input>
        </el-form-item>
        <el-form-item label="客服电话" prop="servicePhone">
          <el-input v-model="info.servicePhone" placeholder="请输入客服电话"></el-input>
        </el-form-item>
        <el-form-item label="管理员姓名" prop="contactName">
          <el-input v-model="info.contactName" placeholder="请输入管理员姓名"></el-input>
        </el-form-item>
        <el-form-item label="管理员身份证" prop="contactIdNumber">
          <el-input v-model="info.contactIdNumber" placeholder="请输入管理员身份证号"></el-input>
        </el-form-item>

        <el-form-item label="管理员手机号" prop="mobilePhone">
          <el-input v-model="info.mobilePhone" placeholder="请输入管理员手机号"></el-input>
        </el-form-item>

        <el-form-item label="管理员邮箱" prop="contactEmail">
          <el-input v-model="info.contactEmail" placeholder="请输入管理员邮箱，不填则采用系统默认邮箱"></el-input>
        </el-form-item>

      </el-form>
    </div>

  </div>
</template>

<script>
  import Upload from '@/components/Upload'
  // import {regionData, CodeToText} from 'element-china-area-data'
  import {provinceAndCityData} from './area-transfer'
  import {mapGetters} from "vuex";

  export default {
    name: '',
    components: {
      Upload
    },
    props: {
      type: {
        type: String,
        default: '',
      }
    },
    computed: {
      ...mapGetters(['employee'])
    },
    mounted: function () {
    },
    data: function () {
      return {
        checked: false,
        options: provinceAndCityData,
        banks: [
          `工商银行`,
          `交通银行`,
          `招商银行`,
          `民生银行`,
          `中信银行`,
          `浦发银行`,
          `兴业银行`,
          `光大银行`,
          `广发银行`,
          `平安银行`,
          `北京银行`,
          `华夏银行`,
          `农业银行`,
          `建设银行`,
          `邮政储蓄银行`,
          `中国银行`,
          `宁波银行`,
          `其他银行`,

        ],
        qualificationTypes: [
          `餐饮`,
          `食品生鲜`,
          `零售批发/生活娱乐/其他`,
        ],
        info: {
          uuid: '',
          name: '',
          licenseNumber: '',
          licenseCopyUrl: '',
          qualifications: '',
          accountName: '',
          accountBank: '',
          bankAddressCode: '',
          bankName: '',
          accountNumber: '',
          idCardName: '',
          idCardNumber: '',
          cardPeriodBegin: '',
          cardPeriodEnd: '',
          cardPeriodEndValue: '',
          idCardCopyUrl: '',
          idCardNationalUrl: '',
          merchantShortName: '',
          servicePhone: '',
          contactName: '',
          contactIdNumber: '',
          mobilePhone: '',
          contactEmail: '',
          qualificationType: '',
          bankAccountType: '',

        },
        rules: {
          name: [
            {required: true, message: '请输入企业名称', trigger: 'blur'},
            {min: 3, message: '长度不少于3个字符', trigger: 'blur'}
          ],
          qualificationType: [
            {required: true, message: '请选取主营业务', trigger: 'blur'}
          ],
          licenseNumber: [
            {required: true, message: '请输入注册号/统一社会信用代码', trigger: 'blur'},
            {min: 3, message: '长度不少于3个字符', trigger: 'blur'}
          ],
          licenseCopyUrl: [
            {required: true, message: '请上传营业执照', trigger: 'blur'},
          ],
        },
        identityRules: {
          idCardName: [
            {required: true, message: '请填写法人名称', trigger: 'blur'},
            {min: 2, message: '长度不少于2个字符', trigger: 'blur'}
          ],
          idCardNumber: [
            {required: true, message: '请填写证件号码', trigger: 'blur'},
            {
              pattern: /^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/,
              message: '证件号码格式有误！',
              trigger: 'blur'
            }
          ],
          cardPeriodBegin: [
            {required: true, message: '请填写身份证有效期', trigger: 'blur'}
          ],
          idCardCopyUrl: [
            {required: true, message: '请上传身份证人像面照片', trigger: 'blur'},
          ],
          idCardNationalUrl: [
            {required: true, message: '请上传身份证国徽面照片', trigger: 'blur'},
          ],
        },
        bankAccountInfoRules: {
          name: [
            {required: true, message: '请填写开户名称', trigger: 'blur'},
            {min: 2, message: '长度不少于2个字符', trigger: 'blur'}
          ],

          bank: [
            {required: true, message: '请选取银行', trigger: 'blur'},
          ],
          bankCode: [
            {required: true, message: '请选取银行区域', trigger: 'blur'},
          ],
          bankName: [
            {required: true, message: '请填写支行名称', trigger: 'blur'},
            {min: 1, message: '长度不少于2个字符', trigger: 'blur'}
          ],
          accountNumber: [
            {required: true, message: '请填写银行账号', trigger: 'blur'},
            {min: 1, message: '长度不少于2个字符', trigger: 'blur'}
          ],
        },
        contactInfoRule: {
          merchantShortName: [
            {required: true, message: '请填写商户简称', trigger: 'blur'},
            {min: 1, message: '长度不少于1个字符', trigger: 'blur'}
          ],
          servicePhone: [
            {required: true, message: '请填写客服电话', trigger: 'blur'},
            {min: 2, message: '长度不少于2个字符', trigger: 'blur'},
            {
              type: 'string',
              required: true,
              message: '请输入手机号',
              trigger: 'blur'
            },
            {
              pattern: /^1[3-9]\d{9}$/,
              message: '手机号格式不对',
              trigger: 'blur'
            }
          ],
          contactName: [
            {required: true, message: '请填写开户名称', trigger: 'blur'},
            {min: 2, message: '长度不少于2个字符', trigger: 'blur'}
          ],

          contactIdNumber: [
            {required: true, message: '请填写证件号码', trigger: 'blur'},
            {
              pattern: /^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/,
              message: '证件号码格式有误！',
              trigger: 'blur'
            }
          ],
          mobilePhone: [
            {
              type: 'string',
              required: true,
              message: '请输入手机号',
              trigger: 'blur'
            },
            {
              pattern: /^1[3-9]\d{9}$/,
              message: '手机号格式不对',
              trigger: 'blur'
            }
          ],
        },
      }
    },
    methods: {
      handleTestchange(val) {
        console.log(val)
      },
      fileUploadSuccess(data) {

      },
      handleCommit() {
        let hasValid = true
        this.$refs.businessForm.validate((valid) => {
          if (!valid) {
            hasValid = false
            return false;
          }
        })

        this.$refs.identityForm.validate((valid) => {
          if (!valid) {
            hasValid = false
            return false;
          }
        })

        this.$refs.contactForm.validate((valid) => {
          if (!valid) {
            hasValid = false
            return false;
          }
        })


        if (this.checked) {
          this.info.cardPeriodEnd = '长期'
        } else {
          this.info.cardPeriodEnd = this.info.cardPeriodEndValue
        }

        if (!this.info.cardPeriodEnd) {
          this.$message.warning('请填写身份证有效期结束时间')
          hasValid = false
        }

        return {
          status: hasValid,
          data: this.info,
        }
      }
    },
  }
</script>

<style lang="scss">
  .wx-apply {
    margin-top: $margin;

    .item {
      .item-title {
        margin-bottom: 20px;

        p {
          padding: 6px;
          background-color: #fff;
          border-bottom: 1px solid #e9eaec;
          font-size: 18px;
        }
      }

      .item-form {
        margin: 0 auto;
        width: 66.66666667%;

        .example {
          text-align: center;

          img {
            max-width: 100px;
          }
        }

        .explain {
          font-size: 12px;
          color: #999;
        }
      }

    }


  }
</style>
