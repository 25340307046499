<template>
  <div class="pay-wx-config">
    <router-back></router-back>

    <div class="pay-wx-config-container">
      <el-steps :active="step" finish-status="success" align-center>
        <el-step title="选取商户类型"></el-step>
        <el-step title="填写申请资料"></el-step>
      </el-steps>
      <el-row class="pay-wx-config-choose" v-show="step===0">
        <el-col :span="10" :offset="2">
          <div class="card " :class="type === 'individual'? 'active': ''" @click="type='individual'">
            <div class="card-head">
              <div>
                <p class="title">个体户</p>
                <span class="desc">已有营业执照及个人银行账户</span>
                <i class="el-icon-circle-check"></i>
              </div>
            </div>
            <div class="card-body" style="padding: 30px;">
              <ul class="detail-wrapper">
                <li>设备支付方式：【微信支付】</li>
                <li>提供资质材料：门店信息、营业执照、食品许可证、身份证、结算账户等资料</li>
                <li>收款方式：经营者个人结算银行卡</li>
                <li>支持个体户申请</li>
              </ul>
              <div class="tags-wrapper">
                <el-tag size="small">个体户经营</el-tag>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="10">
          <div class="card " :class="type !== 'individual'? 'active': ''" @click="type='enterprise'">
            <div class="card-head">
              <div>
                <p class="title">公司</p>
                <span class="desc">已有营业执照及对公银行账户</span>
                <i class="el-icon-circle-check"></i>
              </div>
            </div>
            <div class="card-body" style="padding: 30px;">
              <ul class="detail-wrapper">
                <li>设备支付方式：【微信支付】</li>
                <li>提供资质材料：门店信息、营业执照、食品许可证、身份证、结算账户等资料</li>
                <li>收款方式：公司对公结算账户</li>
                <li>公司用户申请</li>
              </ul>
              <div class="tags-wrapper">
                <el-tag size="small">公司经营</el-tag>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>

      <wx-apply v-show="step!==0" :type="type" ref="wxApply"></wx-apply>

      <div class="step-wrap">
        <el-button type="primary" @click="step=step+1" v-show="step===0">下一步</el-button>
        <el-button type="primary" @click="step=step-1" v-show="step!==0">上一步</el-button>
        <el-button type="primary" @click="handleCommit" v-show="step!==0">完成</el-button>
      </div>
    </div>


  </div>

</template>

<script>
  import WxApply from "./wxApply";
  import {mapGetters} from "vuex";

  import {payWxApply} from '@/api/collection/pay'

  export default {
    name: 'wx-config',
    components: {
      WxApply
    },
    computed: {
      ...mapGetters(['employee'])
    },
    mounted: function () {
    },
    data: function () {
      return {
        step: 0,
        type: 'individual'
      }
    },
    methods: {
      handleCommit() {
        let commit = this.$refs.wxApply.handleCommit()
        if (!commit.status) {
          this.$message.warning('请填写对应信息')
          return
        }

        let data = commit.data

        let usrUuid = this.employee.uuid
        let uuid = new Date().Format('yyyyMMddhhmmss') + usrUuid
        data.uuid = uuid

        if (this.type === `individual`) {
          data.settlementID = `719`
          data.bankAccountType = `BANK_ACCOUNT_TYPE_PERSONAL`
        } else {
          data.settlementID = `716`
          data.bankAccountType = `BANK_ACCOUNT_TYPE_CORPORATE`
        }



        payWxApply(data).then(res => {
          this.$message.success('等待微信审核')
          this.$router.back()
        })
      }
    }
  }
</script>

<style lang="scss">
  .pay-wx-config {
    .step {
      margin-bottom: 32px;
      margin-top: 20px;
    }

    &-container {
      padding: 5% 10%;
    }

    &-choose {
      margin-top: 20px;

      .card {
        cursor: pointer;
        margin: 0 8%;
        border: 1px solid;
        border-radius: 4px;
        @include set_border_color($--border-color-light, $--border-color);

        .card-head {
          border-bottom: 1px solid #e9eaec;
          padding: 20px 30px;
          line-height: 1;
          position: relative;
        }

        i {
          display: none;
          position: absolute;
          right: 10px;
          top: 10px;
          color: #fff;
          font-size: 40px;
        }


        .title {
          display: inline-block;
          width: 100%;
          height: 20px;
          line-height: 20px;
          color: #1c2438;
          font-weight: 700;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 16px;
          margin-bottom: 5px;
        }

        .detail-wrapper {
          list-style-type: none;
          counter-reset: sectioncounter;

          li:before {
            content: counter(sectioncounter) "、";
            counter-increment: sectioncounter;
          }

          li {
            padding: 12px 0;
            border-bottom: 1px solid #e9eaec;
          }
        }

        .desc {
          color: #999;
        }

        .tags-wrapper {
          margin-top: $padding;
        }
      }

      .card.active {
        border: none;
        border-color: $-theme-light-1;
        -webkit-box-shadow: 0 1px 6px 1px $-theme-light-5;
        box-shadow: 0 1px 6px 1px $-theme-light-5;

        .card-head:before {
          position: absolute;
          right: 0;
          top: 0;
          content: "";
          display: block;
          width: 0;
          height: 0;
          border: 50px solid $_theme_color;
          border-left-color: transparent;
          border-bottom-color: transparent;
        }

        i {
          display: inline-block;

        }
      }
    }

    .step-wrap {
      margin-top: 40px;
      text-align: center;
    }

  }

  body.light {
    .pay-wx-config {
      .el-step__head.is-success {
        color: $_theme_color;
        border-color: $_theme_color;
      }

      .el-step__title.is-success {
        color: $_theme_color;
      }
    }
  }
</style>
