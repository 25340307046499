import REGION_DATA from './area'
import cloneDeep from 'lodash/cloneDeep'

const provinceObject = REGION_DATA['86'] // 省份对象
const regionData = REGION_DATA['86']
let provinceAndCityData = []


// // 计算省
// for (const prop in provinceObject) {
//   regionData.push({
//     value: prop.value, // 省份code值
//     label: prop.text // 省份汉字
//   })
// }
// // 计算市
// for (let i = 0, len = regionData.length; i < len; i++) {
//   const provinceCode = regionData[i].value
//   const provinceText = regionData[i].label
//   const provinceChildren = []
//   for (const prop in REGION_DATA[provinceCode]) {
//     provinceChildren.push({
//       value: prop,
//       label: REGION_DATA[provinceCode][prop]
//     })
//   }
//   if (provinceChildren.length) {
//     regionData[i].children = provinceChildren
//   }
// }
provinceAndCityData = cloneDeep(regionData)

// // 计算区
// for (let i = 0, len = regionData.length; i < len; i++) {
//   const province = regionData[i].children
//   const provinceText = regionData[i].label
//   if (province) {
//     for (let j = 0, len = province.length; j < len; j++) {
//       const cityCode = province[j].value
//       const cityText = province[j].label
//       const cityChildren = []
//       for (const prop in REGION_DATA[cityCode]) {
//         cityChildren.push({
//           value: prop,
//           label: REGION_DATA[cityCode][prop]
//         })
//         CodeToText[prop] = REGION_DATA[cityCode][prop]
//         TextToCode[provinceText][cityText][REGION_DATA[cityCode][prop]] = {
//           code: prop
//         }
//       }
//       if (cityChildren.length) {
//         province[j].children = cityChildren
//       }
//     }
//   }
// }

export { provinceAndCityData }
